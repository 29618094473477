<template>
  <div class="admin-box">
    <div class="admin-body">
      <div class="admin-body-header">员工列表</div>
      <div class="table-box" style="text-align: left">
        <van-button
          type="primary"
          size="mini"
          style="margin-bottom: 10px"
          @click="openFrom()"
          >新增员工</van-button
        >
        <table class="fl-table">
          <thead>
            <tr>
              <th>姓名</th>
              <th>账号</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in adminList" :key="item">
              <td>{{ item.name }}</td>
              <td>{{ item.username }}</td>
              <td>
                <van-switch
                  checked="{{checked}}"
                  size="24px"
                  bind:change="onSwitch"
                />
                <!-- <span :style="{ color: item.status == 1 ? 'lightgreen' : 'red' }">{{
                  item.status == 1 ? "正常" : "禁用"
                }}</span> -->
              </td>
              <td>
                <van-button type="default" size="mini" @click="openFrom(item)"
                  >编辑</van-button
                >
                <van-button
                  type="danger"
                  size="mini"
                  style="margin-left: 10px"
                  @click="delUser(item.id)"
                  >删除</van-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="pagination-box d-flex align-center"
          style="margin-top: 10px; padding-right: 20px; justify-content: flex-end"
        >
          <div class="pagination">
            <van-pagination
              v-model="currentPage"
              :page-count="pageCount"
              :show-page-size="3"
              force-ellipses
              @change="changePage"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="edit-user-data">
      <van-popup
        v-model:show="showFrom"
        style="width: 90%; min-height: 60%"
        class="d-flex justify-center align-center"
        closeable
      >
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="state.name"
              name="姓名"
              label="姓名"
              placeholder="姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="state.username"
              name="账号"
              label="账号"
              placeholder="账号"
              :rules="[{ required: true, message: '请填写账号' }]"
            />
            <van-field
              v-model="state.password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
              v-if="isEdit"
            />
            <van-field
              v-model="state.password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
              v-else
              :rules="[{ required: true, message: '请填写密码' }]"
            />
            <van-field
              v-model="state.confirm_password"
              type="password"
              name="密码确认"
              label="密码确认"
              placeholder="密码确认"
              v-if="isEdit"
            />
            <van-field
              v-model="state.confirm_password"
              type="password"
              name="密码确认"
              label="密码确认"
              placeholder="密码确认"
              v-else
              :rules="[{ required: true, message: '请再输入一次密码' }]"
            />
          </van-cell-group>
          <div class="user-status">
            <span class="span-status">用户状态</span>
            <van-popover
              v-model:show="showPopover"
              :actions="actions"
              @select="onSelect"
            >
              <template #reference>
                <van-button type="default" size="mini"
                  >{{ userStatus === 1 ? "正常" : "禁用"
                  }}<span class="gt-span">&gt;</span></van-button
                >
              </template>
            </van-popover>
          </div>
          <div class="user-status">
            <span class="span-status">用户角色</span>
            <van-popover
              v-model:show="showRolePopover"
              :actions="roleList"
              @select="onSelectRole"
            >
              <template #reference>
                <van-button type="default" size="mini">
                  {{ rolename === "" ? "请选择角色" : rolename
                  }}<span class="gt-span">&gt;</span></van-button
                >
              </template>
            </van-popover>
          </div>
          <div class="user-status">
            <span class="span-status">负责校区</span>
            <van-popover
              v-model:show="showCamPopover"
              :actions="campusList"
              @select="onSelectCam"
            >
              <template #reference>
                <van-button type="default" size="mini">
                  {{ camname === "" ? "请选择校区" : camname
                  }}<span class="gt-span">&gt;</span></van-button
                >
              </template>
            </van-popover>
          </div>
          <div class="submit-btn">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import httpAdmin from "../../../api/adminUser";
import httpRole from "../../../api/role";
import httpCampus from "../../../api/getCampus";

import {
  Pagination,
  Button,
  Form,
  Popup,
  CellGroup,
  Field,
  Popover,
  Dialog,
  Toast,
  Switch,
} from "vant";
export default {
  components: {
    "van-pagination": Pagination,
    "van-button": Button,
    "van-popup": Popup,
    "van-form": Form,
    "van-cell-group": CellGroup,
    "van-field": Field,
    "van-popover": Popover,
    "van-switch": Switch,
  },
  name: "StaffList",
  setup() {
    const active = ref(0);
    const currentPage = ref(1);
    const showFrom = ref(false);
    const showPopover = ref(false);
    const showRolePopover = ref(false);
    const showCamPopover = ref(false);

    const actions = [{ text: "正常" }, { text: "禁用" }];
    const userStatus = ref(1);
    const checked = ref(true);
    const onSelect = (action) => {
      if (action.text === "正常") {
        userStatus.value = 1;
      } else {
        userStatus.value = 2;
      }
    };
    //校区id
    const campus_id = ref('')
    //角色名称
    const rolename = ref("");
    

    // 删除
    const delUser = (id) => {
      Dialog.confirm({
        title: "删除",
        message: "是否删除该用户",
      })
        .then(async () => {
          // on confirm
          const res = await httpAdmin.del({ ids: [id] });
          if (res.code == 200) {
            Toast(res.msg);
            getAdminUserList();
          }
        })
        .catch(() => {
          // on cancel
          Toast("取消");
        });
    };
    const adminParams = reactive({
      page: 1,
    });
    // 获取管理员列表
    const adminList = ref([]);
    const pageCount = ref(1);
    const getAdminUserList = async () => {
      const res = await httpAdmin.query(adminParams);
      if (res.code == 200) {
        adminList.value = res.data.list;
        pageCount.value = res.data.pageCount;
        // console.log(adminList);
      }
    };
    getAdminUserList();

    const state = reactive({
      name: "",
      username: "",
      password: "",
      confirm_password: "",
    });

    const isEdit = ref(false);
    const openFrom = (item) => {
      if (item) {
        isEdit.value = true;
        state.name = item.name;
        state.id = item.id;
        state.username = item.username;
        userStatus.value = item.status;
        rolename.value = item.roles[0] ? item.roles[0].name : "";
        roleId.value = item.roles[0].id ? [item.roles[0].id] : [];
        campus_id.value = item.campus_id ? item.campus_id : "";
        camname.value = item.campus_name ? item.campus_name : "";
      }
      showFrom.value = true;
    };

    // 新增、编辑
    const onSubmit = async () => {
      const params = {
        id: state.id,
        username: state.username,
        name: state.name,
        status: userStatus.value,
        password: state.password,
        confirm_password: state.confirm_password,
        role_ids: roleId.value,
        campus_id: campus_id.value,
      };
      console.log(params)
      if (isEdit.value) {
        // console.log(params);
        const res = await httpAdmin.update(params);
        if (res.code == 200) {
          Toast(res.msg);
          getAdminUserList();
        }
      } else {
        const res = await httpAdmin.create(params);
        if (res.code == 200) {
          Toast(res.msg);
          getAdminUserList();
        }
      }
      showFrom.value = false;
    };

    // 获取角色列表
    const roleList = ref([]);
    const getRoleList = async () => {
      const res = await httpRole.query();
      if (res.code == 200) {
        res.data.list.forEach((el) => {
          el.text = el.name;
        });
        roleList.value = res.data.list;
      }
    };
    getRoleList();

    // 选择角色
    const roleId = ref([]);
    const onSelectRole = (obj) => {
      // console.log(obj);
      roleId.value = [obj.id];
      rolename.value = obj.text;
    };

    //获取校区列表
    const campusList = ref([]);
    const getCampusListFun =async ()=>{
      const res = await httpCampus.getCampusList();
      if (res.code == 200) {
        res.data.forEach((el) => {
          el.text = el.campus_name;
        });
        campusList.value = res.data;
      }
    }
    getCampusListFun()

    //选择校区
    const camname = ref('')
    const onSelectCam = (obj)=>{
      console.log(obj)
      campus_id.value = obj.campus_id
      camname.value = obj.text
    }


    // 分页
    const changePage = () => {
      // console.log(currentPage.value);
      adminParams.page = currentPage.value;
      getAdminUserList();
    };
    // 监听表单关闭
    watch(showFrom, (val) => {
      if (!val) {
        isEdit.value = false;
        (state.name = ""),
          (state.username = ""),
          (state.password = ""),
          (state.confirm_password = "");
        userStatus.value = 1;
        rolename.value = "";
        roleId.value = [];
      }
    });

    const onSwitch = () => {};

    return {
      active,
      currentPage,
      showFrom,
      state,
      actions,
      showPopover,
      userStatus,
      adminList,
      pageCount,
      isEdit,
      roleList,
      campusList,
      showRolePopover,
      showCamPopover,
      rolename,
      camname,
      campus_id,
      checked,

      // method
      onSelect,
      onSubmit,
      delUser,
      openFrom,
      onSelectRole,
      changePage,
      onSelectCam,
      onSwitch,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.table-box {
  //   margin: 0 20px;
  box-sizing: border-box;
}
.fl-table {
  border-radius: 10px;
  font-size: 24px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  thead {
    th {
      color: #ffffff;
      background: #4fc3a1;
    }
    th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
    }
  }
  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
    font-size: 36px;
  }
  tr:nth-child(even) {
    background: #f8f8f8;
  }
}
tr {
  height: 80px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.admin-body-header {
  margin: 40px;
  font-weight: 800;
  font-size: 40px;
}
.user-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  margin-left: 60px;
  padding: 20px 0;
  color: #646566;
}
.gt-span {
  margin-left: 10px;
  display: inline-block;
  transform: rotate(90deg);
}
.submit-btn {
  margin-top: 90px;
  margin-right: 20px;
  margin-left: 20px;
}
.span-status {
  margin-right: 60px;
  font-size: 40px;
}
::v-deep .pagination .van-pagination__item {
  height: 64px !important;
}
</style>
