import http from '../lib/http'
import judgStatus from './base'
const RESTURL = '/back/addUser'
const GETURL = '/back/getUser'
const ONEURL = '/back/getUserInfo'
const EDITURL = '/back/editUser'
const DELURL = '/back/delUser'
const ALLUSERURL = '/back/getAllUser'
const API = {
    create(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    update(data) {
        return new Promise((resolve, reject) => {
            http.post(EDITURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    del(data) {
        return new Promise((resolve, reject) => {
            http.post(DELURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    query(data) {
        return new Promise((resolve, reject) => {
            http.post(GETURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    queryAllUser() {
        return new Promise((resolve, reject) => {
            http.post(ALLUSERURL).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    one(data) {
        return new Promise((resolve, reject) => {
            http.post(ONEURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default API
